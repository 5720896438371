#admin {
  display: grid;
  grid-template: 1fr 1fr 1fr 1fr/ 1fr 1fr 1fr;
  height: 80%;
  margin-left: 5%;
  margin-right: 5%;
  grid-gap: 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
#login {
  justify-self: center;
  grid-area: 2/2/2/2;
  height: 300px;
  width: 300px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
#submit {
  margin: 20px;
}
#manage {
  grid-area: 1 / 1 / 5 / 2;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  background-color: white;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 20px;
  max-width: 450px;
}
#list {
  width: 90%;
  border: 2px solid rgb(134, 134, 134);
  border-radius: 10px;
  height: 90%;
}
.litem {
  margin-left: 10px;
  margin: 5px;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  background-color: rgb(244, 248, 255);
  border-radius: 10px;
}
.litem > * {
  display: flex;
  justify-content: center;
  align-items: center;
}
#add {
  position: absolute;
  right: 40px;
  bottom: 40px;
}
#outer {
  grid-area: 1 / 2 / 5 /3;
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-flow: column;
  background-color: white;
  border-radius: 20px;
  align-items: center;
}
/* #edit-user form {
  width: 100%;
} */
#edit-user {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  height: 100%;
  width: 80%;
}
