#dropdown {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  padding: 10px;
  justify-self: center;
}
#header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  background-color: white;
  /* margin: 40px; */
  padding: 25px;
}
#logo {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

  font-size: x-large;
  font-weight: bold;
  text-align: center;
  margin: 10px;
}
#dropdown > *,
#links > * {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 10px;
  text-decoration: none;
  color: black;
  text-align: center;
}
/* #dropdown-name */
#links {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
a {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-decoration: none;
  color: black;
  margin: 10px;
  text-align: center;
  justify-content: center;
}
#logoimg {
  height: 90px;
  margin: 0;
  padding: 0%;
  margin-right: 10px;
}
