#contact-us-title {
  font-weight: bold;
  font-size: 25px;
  color: #800021;
}
#contact {
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: white;
  margin-top: 20px;
  padding: 20px;
  width: 75%;
  height: 100%;
  border-radius: 20px;
  max-height: 600px;
  max-width: 1000px;
  /** breakpoint at 715 width*/
}
#contact-img {
  position: fixed;
}
#info-contact {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: bold;
  background-repeat: no-repeat;
  /* background-image: url("https://i.ibb.co/1mbrdz2/logo.png"); */
  background-position: center;
}

#or {
  margin: 15px;
}
#info-contact > * {
  margin-bottom: 10px;
  margin-left: 25%;
}

#line {
  display: flex;
  width: 2px;
  background-color: black;
  height: 100%;
}
#make {
  height: 100%;
  width: 300px;
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#container {
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
#aptform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 80%;
  justify-self: center;
  align-self: center;
}
#aptform > * {
  margin: 10px;
  width: 100%;
}
