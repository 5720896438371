html,body,:root{
  height: 100vh;
}

#home {
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: space-between;
}

#motto {
  height: 225px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  font-weight: bold;
  font-size: 40px;
  color: #800021;
}

#services {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
#title {
  font-size: x-large;
  font-weight: bold;
  margin: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#info-cards {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-around;
  max-width: 1350px;
}
.service-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: white;
  width: 250px;
  border-radius: 15px;
  box-shadow: 4px 4px 4px #8c1018;
  margin: 25px;
  margin-top: 0%;
  font-weight: 600;
}
