.userlist {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}
.user {
  height: 400px;
  width: 400px;
  display: grid;
  grid-template-rows: 1fr 1fr 5fr 2.5fr;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 20px;
  border-radius: 15px;
  max-width: 450px;
  box-shadow: 0px 0px 10px rgb(194, 194, 194);
}
.name {
  margin-top: 20px;
  font-size: 35px;
  font-weight: bold;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
  /* color: #800021; */
  color: #9b0027;
}
.userimg {
  height: 215px;
  width: 215px;
}
.pos {
  text-align: center;
  font-weight: bold;
}
.bio {
  text-align: center;
}
#mtt {
  margin: 40px;
  margin-top: 55px;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  /* color: #800021; */
  color: #9b0027;
  text-shadow: 2px 2px 4px rgb(253, 253, 253);
}
