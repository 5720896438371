#info {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  background-color: #800021;
  color: white;
  padding: 5px;
}
#fax {
  margin-right: 20px;
  margin-left: 10px;
}
#address {
  margin-right: 10px;
}
