.footer {
    /* position: sticky; */
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    /* height: 100px; */
    /* align-items: center; */
    justify-content: center;
    background-color: #dbdbdb;
    text-align: center;
    padding: 20px;
}